import { useQuery } from '@tanstack/react-query';
import { localFetch } from './fetch';
import { NewsFilterProps, NewsProps } from './type';

export function useGetNews(token: string, filters?: NewsFilterProps) {
  const res = useQuery({
    queryKey: ['news', token, JSON.stringify(filters ?? {})],
    queryFn: () => {
      const searchParams = new URLSearchParams({
        page: filters?.page?.toString() ?? '1',
      }).toString();
      return localFetch<NewsProps[]>('/api/news?' + searchParams);
    },
    // enabled: !!token,
  });

  return {
    ...res,
    data: res.data?.data,
    meta: res.data?.meta,
  };
}

export function useGetNewsById(id: string) {
  const res = useQuery({
    queryKey: ['news', id],
    queryFn: () => localFetch<NewsProps>(`/api/news/${id}`),
  });

  return { ...res, data: res.data?.data };
}
