'use client';
import { CarouselApi } from '@/components/ui/carousel';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';

export function CarouselIndicators({
  api,
  handleResetOnInteraction,
}: {
  api?: CarouselApi;
  handleResetOnInteraction?: () => void;
}) {
  const [activeIndex, setActiveIndex] = useState(1);
  const numIndicators = 4;
  const swapDuration = 1; // Duration of the swap animation

  const onThumbClick = useCallback(
    (index: number) => {
      if (!api) return;

      handleResetOnInteraction?.();

      setActiveIndex(index);
      api.scrollTo(index);
    },
    [api]
  );

  const onSelect = useCallback(() => {
    if (!api) return;
    const i = api.selectedScrollSnap();

    setActiveIndex(i);
  }, [api]);

  useEffect(() => {
    if (!api) return;
    onSelect();

    api.on('select', onSelect).on('reInit', onSelect);
  }, [api, onSelect]);

  const indicatorVariants = {
    active: {
      backgroundColor: '#BD8B2C',
      width: '80px',
      height: '6px',
      borderRadius: '2px',
      x: 0,
    },
    inactive: {
      backgroundColor: 'gray',
      width: '80px',
      height: '6px',
      borderRadius: '2px',
      x: 0,
    },
    moveIn: {
      x: 0,
      transition: { type: 'spring', stiffness: 100, damping: 25 },
    },
    moveOut: {
      x: ['-100%', '0%'], // Moves from 0 to -100%
      transition: { type: 'spring', stiffness: 50, damping: 15, duration: swapDuration },
    },
  };

  const getIndicatorProps = (index: number) => {
    const isActive = index === activeIndex;
    const isNext = index === (activeIndex + 1) % numIndicators;
    const isPrevious = index === (activeIndex - 1 + numIndicators) % numIndicators;

    return {
      variants: indicatorVariants,
      initial: isActive ? 'active' : 'inactive',
      animate: isActive ? 'active' : isNext ? 'moveIn' : isPrevious ? 'moveOut' : 'inactive',
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 15,
        duration: swapDuration,
      },
    };
  };

  return (
    <div className='flex justify-center select-none w-full gap-10 items-center'>
      <div className='flex  gap-2'>
        {Array.from({ length: numIndicators }).map((_, index) => (
          <motion.div
            key={index}
            onClick={() => onThumbClick(index)}
            className='indicator select-none cursor-pointer md:w-auto !w-10'
            {...getIndicatorProps(index)}
          />
        ))}
      </div>
    </div>
  );
}
