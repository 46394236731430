import { Title } from '@/components/shared/title';
import { Lang } from '@/i18n/settings';
import { cn } from '@/lib/utils';

export type DescriptionProps = { one: string; two: string; three: string };

type Props = {
  data: DescriptionProps;
  lang: Lang;
};

export function Description({ data, lang }: Props) {
  return (
    <div className='flex flex-col  justify-start gap-4 lg:gap-6 xl:gap-10 w-full xl:w-[40%] md:w-[50%] md:mr-auto h-full'>
      <div className='grid gap-4 items-center grid-cols-2 w-full'>
        <div className='w-full flex flex-row-reverse'>
          <span
            className={cn(
              '2xl:h-10 md:h-7 h-5 bg-primary mr-auto w-[calc(100%-6rem)] md:w-[calc(100%-8rem)]',
              lang != 'ar' ? 'ml-auto mr-0' : ''
            )}
          />
        </div>
        <Title className={cn('text-2xl lg:text-4xl text-primary', lang != 'ar' ? 'font-alt' : '')}>
          {data.one}
        </Title>
      </div>
      <div className='grid gap-4 items-center grid-cols-2 w-full'>
        <div className='w-full flex'>
          <span
            className={cn(
              '2xl:h-10 md:h-7  h-5 bg-primary mr-auto w-[calc(100%-4rem)] md:w-[calc(100%-4rem)]',
              lang != 'ar' ? 'ml-auto mr-0' : ''
            )}
          />
        </div>
        <Title className={cn('text-2xl lg:text-4xl text-primary', lang != 'ar' ? 'font-alt' : '')}>
          {data.two}
        </Title>
      </div>
      <div className='grid gap-4 items-center grid-cols-2 w-full'>
        <div className='w-full flex'>
          <span className='2xl:h-10 md:h-7 h-5 bg-primary mr-auto w-[calc(100%-1rem)] md:w-[calc(100%)]' />
        </div>
        <Title
          className={cn(
            'text-2xl lg:text-4xl whitespace-nowrap text-primary',
            lang != 'ar' ? 'font-alt' : ''
          )}
        >
          {data.three}
        </Title>
      </div>
    </div>
  );
}
