'use client';
import { Button } from '@/components/ui/button';
import { CarouselApi } from '@/components/ui/carousel';
import { cn } from '@/lib/utils';
import { EmblaViewportRefType } from 'embla-carousel-react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

type ControlProps = {
  api?: CarouselApi;
  emblaThumbsRef?: EmblaViewportRefType;
  selectedIndex?: number;
  onThumbClick?: (index: number) => void;
  handleResetOnInteraction?: () => void;
  className?: string;
  totalSlides: number;
  options?: {
    button: {
      className?: string;
    };
  };
};

export function CarouselControls({
  api,
  emblaThumbsRef,
  onThumbClick,
  selectedIndex,
  className,
  handleResetOnInteraction,
  totalSlides,
  options,
}: ControlProps) {
  return (
    <div className={cn('flex  items-center gap-4', className)}>
      <div className='flex-shrink-0'>
        <Button
          onClick={() => {
            handleResetOnInteraction?.();
            api?.canScrollPrev() && api?.scrollPrev();
          }}
          variant={'outline'}
          size={'icon'}
          className={cn('bg-transparent rounded-full text-background', options?.button?.className)}
        >
          <ChevronRight />
        </Button>
      </div>

      <div className='overflow-hidden' ref={emblaThumbsRef}>
        <div className='flex flex-row items-center'>
          {Array.from({ length: totalSlides }).map((_, index) => (
            <Thumb
              key={index}
              onClick={() => onThumbClick?.(index)}
              selected={index === selectedIndex}
              index={index}
            />
          ))}
        </div>
      </div>
      <div className='flex-shrink-0'>
        <Button
          onClick={() => {
            handleResetOnInteraction?.();
            api?.canScrollNext() && api?.scrollNext();
          }}
          variant={'outline'}
          size={'icon'}
          className={cn('bg-transparent rounded-full text-background', options?.button?.className)}
        >
          <ChevronLeft />
        </Button>
      </div>
    </div>
  );
}

type ThumbProps = {
  selected: boolean;
  index: number;
  onClick: () => void;
  className?: string;
};

export const Thumb: React.FC<ThumbProps> = (props) => {
  const { selected, index, onClick } = props;

  return (
    <div className={'rounded-full flex-shrink-0'.concat(selected ? ' bg-white text-black  ' : '')}>
      <Button
        variant={'ghost'}
        onClick={onClick}
        type='button'
        className={'rounded-full flexflex-shrink-0 text-xs md:text-base'}
        size={'icon'}
      >
        {index + 1}
      </Button>
    </div>
  );
};
