'use client';
import Reveal from '@/components/shared/animate/reveal';
import { Image } from '@/components/shared/image';
import { AspectRatio } from '@/components/ui/aspect-ratio';
import { Shell } from '@/components/ui/shell';
import AlSheikh from '~/public/assets/images/al-sheikh.png';
import NextImage from 'next/image';
import { Title } from '@/components/shared/title';
import CUT from '~/public/assets/images/cut.png';
import { useWindowSize } from '@/hooks/use-window-size';
import { useEffect, useState } from 'react';

export function SectionThree() {
  const { width } = useWindowSize();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <div className='relative bg-primary'>
      <div className='w-full select-none md:h-[200px] hidden lg:h-auto bg-primary md:flex items-end lg:block md:bg-transparent absolute bottom-0 z-10'>
        <NextImage
          src={CUT}
          alt=''
          className='w-full  bg-primary md:bg-transparent'
          width={50000}
          height={50000}
          loading='eager'
          priority
        />
      </div>
      <div className=' bg-background'>
        <Shell
          as={'div'}
          className=' lg:gap-20 md:items-start lg:items-start flex-col  lg:flex md:flex-row '
        >
          <div className='md:basis-1/2 relative md:min-h-[500px] xl:min-h-[900px]  pb-10 md:pb-0 flex items-center overflow-hidden'>
            {isClient ? (
              <>
                {width > 767 ? (
                  <AspectRatio ratio={9 / 12.5}>
                    <Image src={AlSheikh} alt='Al-Sheikh' className='w-[1000px] object-cover' />
                  </AspectRatio>
                ) : (
                  <AspectRatio ratio={9 / 10}>
                    <Image src={AlSheikh} alt='Al-Sheikh' className='w-[1000px] object-cover' />
                  </AspectRatio>
                )}
              </>
            ) : null}
            <div className='absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-100'></div>
          </div>
          <Reveal className='flex flex-col pb-20 md:pb-0 gap-4 lg:mt-2 xl:mt-12 md:gap-8 md:basis-1/2'>
            <div className='flex flex-col gap-6 md:gap-4 lg:gap-6 xl:gap-10'>
              <h4 className='text-primary font-semibold text-2xl md:text-xl lg:text-2xl xl:text-4xl'>
                معالي المستشار
              </h4>
              <Title className='text-primary whitespace-nowrap text-[28px] font-bold md:text-3xl  lg:text-4xl xl:text-[52px]'>
                تـركي بن عبدالمحسن آل الشيخ
              </Title>
              <h4 className='text-primary font-semibold text-2xl md:text-xl lg:text-2xl xl:text-4xl '>
                رئيس مجلس إدارة الهيئة العامة للترفيه
              </h4>
            </div>
            <div className=' w-full text-xl md:text-lg font-semibold  lg:text-xl !leading-9 lg:!leading-[46px] '>
              <p>
                رئيس مجلس إدارة الهيئة العامة للترفيه‬⁩ يعلن عن إطلاق "جائزة القلم الذهبي للأدب
                الأكثر تأثيراً" والتي تركز على الأعمال الروائية الأكثر قابلية للتحويل لأعمال
                سينمائية بمجموع جوائز تصل لـ 740 ألف دولار وإنتاجات سينمائية.
              </p>
            </div>
          </Reveal>
        </Shell>
      </div>
    </div>
  );
}
