type PrizesProps = {
  title: string;
  subtitle: React.ReactNode;
  details: React.ReactNode;
  scope: string;
};

const novelCategories = [
  'أفضل رواية كوميدية',
  'أفضل رواية للتشويق والإثارة',
  'أفضل رواية واقعية',
  // More items...
  'أفضل رواية فانتازيا',
  'أفضل رواية غموض وجريمة',
  // More items...
  'أفضل رواية رومانسية',
  'أفضل رواية تاريخية',
  'أفضل رواية رعب',
];

const screenPlay = [
  {
    title: 'المركز الأول',
    details: (
      <span>
        <span className='font-bold text-xl'>100 ألف دولار</span> <br /> وتحويل العمل إلى فيلم
        سينمائي
      </span>
    ),
  },
  {
    title: 'المركز الثاني',
    details: (
      <span>
        <span className='font-bold text-xl'>50 ألف دولار</span> <br /> وتحويل العمل إلى فيلم سينمائي
      </span>
    ),
  },

  {
    title: 'المركز الثالث',
    details: (
      <span>
        <span className='font-bold text-xl'>30 ألف دولار</span>
      </span>
    ),
  },
];

const additionalPrizes = [
  {
    title: 'جائزة أفضل عمل روائي مترجم',
    details: (
      <span>
        جائزة قدرها <span className='font-bold text-xl'>100 ألف دولار</span>
      </span>
    ),
    // details: '"جائزة أفضل عمل روائي مترجم" : جائزة قدرها 100 ألف دولار',
  },
  {
    title: 'جائزة الناشرين العرب',
    details: (
      <span>
        جائزة قدرها <span className='font-bold text-xl'>50 ألف دولار</span>
      </span>
    ),
    // details: '"جائزة أفضل ناشر عربي": جائزة قدرها 50 ألف دولار',
  },

  {
    title: 'جائزة الجمهور',
    details: (
      <span>
        جائزة قدرها <span className='font-bold text-xl'>30 ألف دولار</span>
      </span>
    ),
    // details: '"جائزة الجمهور": جائزة قدرها 30 ألف دولار',
  },
];

const grandPrizes = [
  {
    title: 'المركز الأول',

    details: (
      <span>
        <span className='font-bold text-xl'>100 ألف دولار</span> <br /> وتحويل الرواية إلى فيلم
        سينمائي
      </span>

      // <span>
      //   <span className='font-bold text-xl'>100 ألف دولار</span> و
      //   <span className='normal'>تحويل الرواية إلى فيلم سينمائي</span>
      // </span>
    ),
    // details: 'تحويل الرواية إلى فيلم سينمائي، بالإضافة إلى مبلغ 100 ألف دولار',
  },
  {
    title: 'المركز الثاني',
    details: (
      <span>
        <span className='font-bold text-xl'>50 ألف دولار</span> <br /> وتحويل الرواية إلى فيلم
        سينمائي
      </span>

      // <span>
      //   <span className='font-bold text-xl'>50 ألف دولار</span> و
      //   <span className='normal'>تحويل الرواية إلى فيلم سينمائي</span>
      // </span>
    ),
    // details: 'تحويل الرواية إلى فيلم سينمائي، بالإضافة إلى مبلغ 50 ألف دولار',
  },
  {
    title: 'المركز الثالث',
    details: (
      <span>
        مبلغ <span className='font-bold text-xl'>30 ألف دولار</span>
      </span>
    ),
  },
];

export const prizes: PrizesProps[] = [
  // {
  //   scope: '1',
  //   title: 'الجوائز الكبرى',
  //   subtitle: 'المركز الأول',
  //   details: 'تحويل الرواية إلى فيلم سينمائي، بالإضافة إلى مبلغ 100 ألف دولار',
  // },
  // {
  //   scope: '1',
  //   title: 'الجوائز الكبرى',
  //   subtitle: 'المركز الثاني',
  //   details: 'تحويل الرواية إلى فيلم سينمائي، بالإضافة إلى مبلغ 50 ألف دولار',
  // },
  // {
  //   scope: '1',
  //   title: 'الجوائز الكبرى',
  //   subtitle: 'المركز الثالث',
  //   details: 'مبلغ 30 ألف دولار',
  // },
  {
    scope: '1',
    title: 'الجوائز الكبرى',
    // subtitle: 'برعاية سمو وزير الثقافة',
    subtitle: '',
    details: <GrandPrizes />,
  },
  {
    scope: '2',
    title: 'جائزة مسار الرواية',
    subtitle: (
      <span>
        سيحصل الفائز بالمركز الأول في كل فئة على مبلغ{' '}
        <span className='font-bold'>25 ألف دولار</span>، بإجمالي{' '}
        <span className='font-bold'>200 ألف دولار</span> عن كل الفئات
      </span>
    ),

    // subtitle:
    //   'سيحصل الفائز بالمركز الأول في كل فئة على مبلغ 25 ألف دولار، بإجمالي 200 ألف دولار عن كل الفئات',
    details: <NovelCategory />,
  },
  {
    scope: '3',
    title: 'أفضل سيناريو مقدم من عمل أدبي',
    subtitle: '',
    details: <ScreenPlay />,
  },
  {
    scope: '4',
    title: 'جوائز إضافية',
    subtitle: '',
    details: <AdditionalPrizes />,
  },
];

function NovelCategory() {
  return (
    <ul className='list-disc grid md:grid-cols-3  gap-x-6 gap-4 md:gap-x-12 xl:gap-x-20'>
      {novelCategories.map((category, index) => (
        <li key={index} className='text-start'>
          {category}
        </li>
      ))}
    </ul>
  );
}

function ScreenPlay() {
  return (
    <ul className='grid md:grid-cols-3  gap-x-6 gap-4 md:gap-x-12 xl:gap-x-20'>
      {screenPlay.map((category, index) => (
        <li key={index} className='max-w-[300px]  flex flex-col gap-4'>
          <p className='text-primary text-center text-lg'>{category.title}</p>
          <p className='text-center '>{category.details}</p>
        </li>
      ))}
    </ul>
  );
}

function AdditionalPrizes() {
  return (
    <ul className='grid md:grid-cols-3  gap-x-6 gap-4 md:gap-x-12 xl:gap-x-20'>
      {additionalPrizes.map((category, index) => (
        <li key={index} className='max-w-[300px] md:max-w-max flex flex-col gap-4'>
          <p className='text-primary text-center text-lg'>{category.title}</p>
          <p className='text-center '>{category.details}</p>
        </li>
      ))}
    </ul>
  );
}

function GrandPrizes() {
  return (
    <ul className='grid md:grid-cols-3  gap-x-6 gap-4 md:gap-x-12 xl:gap-x-20'>
      {grandPrizes.map((category, index) => (
        <li key={index} className='max-w-[300px]  flex flex-col gap-4'>
          <p className='text-primary text-center text-lg'>{category.title}</p>
          <p className='text-center '>{category.details}</p>
        </li>
      ))}
    </ul>
  );
}
