import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/public/assets/images/best-writer.png");
;
import(/* webpackMode: "eager" */ "/app/public/assets/sponsors/gea-original.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/sponsors/golden-pen-original.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/sponsors/logo-gold.png");
;
import(/* webpackMode: "eager" */ "/app/public/assets/sponsors/rs-original-2.svg");
;
import(/* webpackMode: "eager", webpackExports: ["SectionFive"] */ "/app/src/app/[lang]/(layout)/(lobby)/(sections)/section-five/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["JuriesSection"] */ "/app/src/app/[lang]/(layout)/(lobby)/(sections)/section-four/juries.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SortingCommitteeSection"] */ "/app/src/app/[lang]/(layout)/(lobby)/(sections)/section-four/sorting-committee.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SectionOne"] */ "/app/src/app/[lang]/(layout)/(lobby)/(sections)/section-one/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[lang]/(layout)/(lobby)/(sections)/section-six/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SectionThree"] */ "/app/src/app/[lang]/(layout)/(lobby)/(sections)/section-three/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/shared/animate/reveal.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/shared/image/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/Link/link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AspectRatio"] */ "/app/src/components/ui/aspect-ratio.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/app/src/components/ui/separator.tsx");
