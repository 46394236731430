'use client';
import Reveal from '@/components/shared/animate/reveal';
import { Carousel } from '@/components/shared/carousel';
import { CarouselApi, CarouselItem } from '@/components/ui/carousel';
import { Shell } from '@/components/ui/shell';
import { useWindowSize } from '@/hooks/use-window-size';
import useEmblaCarousel from 'embla-carousel-react';
import { useInView } from 'framer-motion';
import React from 'react';
import { AnimatedCommitteeCard } from './components/animated-card';
import { CarouselControls } from './components/carousel';
import { committes } from './data';

export function SortingCommitteeSection() {
  const ref = React.useRef(null);
  const { width: screenWidth } = useWindowSize();

  const isInView = useInView(ref, {
    amount: 0.5,
  });

  const [isClient, setIsClient] = React.useState(false);

  const [onHover, setOnHover] = React.useState({ isActive: false, index: 0 });
  const [api, setApi] = React.useState<CarouselApi>();
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
    direction: 'rtl',
  });

  function handleResetOnInteraction(stop: boolean) {
    const autoPlay = api?.plugins()?.autoplay;
    if (!autoPlay) return;

    if (stop) {
      autoPlay?.isPlaying() && autoPlay?.reset();
    }
  }

  const onThumbClick = React.useCallback(
    (index: number) => {
      if (!api || !emblaThumbsApi) return;
      api.scrollTo(index);
      handleResetOnInteraction(true);
    },
    [api, emblaThumbsApi]
  );

  const onSelect = React.useCallback(() => {
    if (!api || !emblaThumbsApi) return;

    setSelectedIndex(api.selectedScrollSnap());
    emblaThumbsApi.scrollTo(api.selectedScrollSnap());
  }, [api, emblaThumbsApi, setSelectedIndex]);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  React.useEffect(() => {
    if (isInView && !onHover.isActive) {
      api?.plugins()?.autoplay?.play();
    } else {
      api?.plugins()?.autoplay?.stop();
    }
  }, [isInView, onHover]);

  React.useEffect(() => {
    if (!api) return;
    onSelect();

    api.on('select', onSelect).on('reInit', onSelect);
  }, [api, onSelect]);

  return (
    <Shell as={'div'} className='relative flex flex-col px-0'>
      <div className='flex flex-col md:flex-row gap-8 md:gap-10 lg:mx-auto lg:w-full justify-between md:items-end'>
        <Reveal className='flex flex-col ml-auto gap-8 w-full sm:max-w-[calc(100%_/_2.5)] lg:max-w-[calc(100%_/_3)] xl:max-w-[calc(100%_/_3)]'>
          <div className='grid gap-4'>
            <h1 className='text-background font-bold text-5xl 2xl:text-[50px] '>لجـنـة الـفرز</h1>
            {/* <h1 className='text-background font-bold text-5xl 2xl:text-[50px] '>الأولي</h1>/ */}
          </div>
          <div className='grid gap-4 md:gap-6 xl:gap-8'>
            <span className='2xl:h-10 md:h-8 h-6 bg-black w-[calc(100%-12rem)] md:w-[calc(100%-10rem)]' />
            <span className='2xl:h-10 md:h-8 h-6 bg-black w-[calc(100%-8rem)] md:w-[calc(100%-6rem)]' />
            <span className='2xl:h-10 md:h-8 h-6 bg-black w-[calc(100%-4rem)] md:w-[calc(100%-2rem)]' />
          </div>

          {isClient && screenWidth >= 768 ? (
            <CarouselControls
              className='flex selection:w-full'
              api={api}
              totalSlides={committes.length}
              handleResetOnInteraction={() => {
                handleResetOnInteraction(true);
              }}
              emblaThumbsRef={emblaThumbsRef}
              selectedIndex={selectedIndex}
              onThumbClick={onThumbClick}
            />
          ) : null}
        </Reveal>
        <div ref={ref} className='md:max-w-[calc(100%_/_2)] w-full lg:max-w-[calc(100%_/_1.5)]'>
          <Carousel
            setApi={setApi}
            options={{
              carousel: {
                loop: true,
              },
              content: {
                // className: 'gap-2 ',
              },
            }}
          >
            {committes.map((data, index) => (
              <CarouselItem
                key={index}
                onMouseEnter={() => {
                  setOnHover({
                    isActive: true,
                    index,
                  });
                }}
                onMouseLeave={() => {
                  setOnHover({
                    isActive: false,
                    index,
                  });
                }}
                onClick={() => {
                  setOnHover((prev) => ({
                    isActive: !prev.isActive,
                    index,
                  }));
                  onThumbClick(index);
                }}
                className=' basis-4/5 sm:basis-1/2 md:basis-4/5 lg:basis-1/2 w-full'
              >
                <AnimatedCommitteeCard
                  data={data}
                  isActive={onHover.isActive && onHover.index === index}
                />
              </CarouselItem>
            ))}
          </Carousel>
        </div>
      </div>

      {isClient && screenWidth < 768 ? (
        <CarouselControls
          className='justify-center'
          api={api}
          totalSlides={committes.length}
          handleResetOnInteraction={() => {
            handleResetOnInteraction(true);
          }}
          emblaThumbsRef={emblaThumbsRef}
          selectedIndex={selectedIndex}
          onThumbClick={onThumbClick}
        />
      ) : null}
    </Shell>
  );
}
