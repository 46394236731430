import { AspectRatio } from '@/components/ui/aspect-ratio';
import HERO_IMAGE from '~/public/assets/images/Vector1.png';
import { Image } from './shared/image';

export default function HeroVideo() {
  return (
    <>
      <div className='gradient-background'></div>
      <video
        className='video-overlay hidden md:block'
        controls={false}
        autoPlay
        muted
        loop
        playsInline
      >
        <source src='/assets/videos/pen.mp4' type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      <AspectRatio ratio={16 / 0.3}>
        <Image
          src={HERO_IMAGE}
          // width={1400}
          // height={1400}
          alt=''
          className='w-full mask-image object-cover'
        />
        {/* <div className='mask'></div> */}
      </AspectRatio>

      <Image
        // id='hero-video-fallback'
        alt='Fallback Image'
        src={HERO_IMAGE}
        className='w-full h-full md:hidden object-cover'
        width={1000}
        height={1000}
      />
    </>
  );
}
