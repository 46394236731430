export type CommitteeProps = {
  name: string;
  title: string;
  img: string;
  description: string;
};
export const presidents: CommitteeProps[] = [
  {
    name: 'الدكتور سعد البازعي',
    title: 'رئيس الجائزة',
    img: '/assets/images/jury/jury-1.jpg',
    description:
      'يملك خبرة واسعة في عدة مجالات أبرزها التأليف والنقد والترجمة،وسبق للبازعي أن عمل أستاذاً للأدب المقارن بجامعة الملك سعود وعضواً في مجلس الشورى، ورئاسة النادي الأدبي بالرياض، حيث له العديد من الكتب والأبحاث والمقالات باللغتين العربية والإنجليزية،وله مشاركات في عدد من المؤتمرات والندوات المحلية والعربية والدولية، إضافة إلى عضوية الصندوق الدولي لدعم الثقافة باليونسكو ورئاسة لجنة جائزة البوكر لعام 2014. كما حصل على العديد من الجوائز منها جائزة وزارة الثقافة والإعلام السعودية للكتاب لعام 2012، وجائزة السلطان قابوس في النقد الأدبي لعام 2017، وجائزة البحرين للكتاب لعام 2018، وجائزة الدوحة للكتاب العربي في عام 2024، كما كرم في اجتماع وزراء الثقافة لدول مجلس التعاون لعام 2019.',
  },
  {
    name: 'عبدالله بن بخيت',
    title: 'نائب رئيس الجائزة',
    img: '/assets/images/jury/jury-2.jpg',
    description:
      'عبدالله بن بخيت هو كاتب سعودي معروف، اشتهر بكتاباته الأدبية والنقدية، بالإضافة إلى كونه من أبرز الصحفيين في المملكة العربية السعودية. يكتب بن بخيت في العديد من المجالات مثل الأدب، الثقافة، والفكر الاجتماعي، وسبق له أن نشر مقالات وكتبًا تناولت قضايا اجتماعية وثقافية متنوعة. يعتبر بن بخيت من الأصوات البارزة في المشهد الثقافي السعودي وله تأثير ملحوظ في نشر الثقافة والفكر.',
  },
];

export const juries: CommitteeProps[] = [
  // novelists
  {
    name: 'أحمد مراد',
    title: 'الروائي',
    img: '/assets/images/jury/jury-3.jpeg',
    description:
      'كاتب وروائي وسيناريست مصري، ولد في عام 1978 في حي السيدة زينب بالقاهرة. تخرج من المعهد العالي للسينما قسم التصوير السينمائي عام 2001 بترتيب الأول على قسمه. بدأ مراد مشواره الأدبي برواية "فيرتيجو" عام 2007، التي تحولت إلى مسلسل تلفزيوني. أصدر بعدها عدة روايات ناجحة مثل "تراب الماس"، "الفيل الأزرق"، و"1919"، "أبو الهول" والعديد من هذه الأعمال تم تحويلها إلى أفلام سينمائية. نال أحمد مراد عدة جوائز تقديرية، من بينها جائزة "البحر الأبيض المتوسط للثقافة" في عام 2013 عن روايته "فيرتيجو"، جائزة أفضل كتاب في معرض القاهرة الدولي للكتاب عن رواية "الفيل الأزرق" والتي فازت بجائزة القائمة القصيرة بالبوكر العربية لعام 2014 كما حصل على جائزة الدولة للتفوق في الأدب عن مجمل أعماله في عام 2018.',
  },
  {
    name: 'تامر ابراهيم',
    title: 'الروائي',
    img: '/assets/images/jury/jury-4.jpeg',
    description:
      'كاتب وطبيب بشري تخرج من كلية طب عين شمس سنة ٢٠٠٣ وتفرغ بعدها للكتابة الروائية والدرامية، أشتهر بتخصصه في أدب الرعب ومن أعماله رواية "صانع الظلام" "والليلة الثالثة والعشرون" ومجموعة "حكايات الموتى" و"حكايات القبو" كما قدم في التلفزيون أعمال درامية مثل "لحظات حرجة" و"الغرفة 207"',
  },
  {
    name: '⁠عبده خال',
    title: 'الروائي',
    img: '/assets/images/jury/jury-5.jpeg',
    description:
      'عبده خال هو كاتب وروائي سعودي شهير، ولد في عام 1962 في منطقة جازان. يعتبر من أبرز الأدباء السعوديين المعاصرين وله العديد من الأعمال الأدبية التي نالت استحسان النقاد والجمهور وترجمت الى لغات متعددة ومن أشهر رواياته "ترمي بشرر" التي فازت بجائزة بوكر العربية في عام 2010. تتميز كتاباته بالغوص في تفاصيل الحياة الاجتماعية والثقافية والسياسية في السعودية.',
  },
  {
    name: '⁠سعود السنعوسي',
    title: 'الروائي',
    img: '/assets/images/jury/jury-6.jpeg',
    description:
      'روائي كويتي، له ست روايات منها "ساق البامبو" و"أسفار مدينة الطين" حاصل على جائزة الدولة التشجيعية في الكويت، والجائزة العالمية للرواية العربية (البوكر). وترجمت أعماله إلى 14 لغة.',
  },
  {
    name: 'بدرية البشر',
    title: 'الروائي',
    img: '/assets/images/jury/jury-7.jpeg',
    description:
      'روائية، وكاتبة، حاصلة على درجة الدكتوراة في فلسفلة الآداب، حصلت على جائزة الصحافة العربية لإفضل كاتب عربي ، لها العديد من المؤلفات الروائية والإنتاج الفكري والعلمي ، ترجمت  رواياتها  للإنجليزية والألمانية والفرنسية والايطالية والصينية،عملت عضوا  في الهيئة العلمية لجائزة الشيخ زايد وجائزة الصحافة العربية .',
  },
  {
    name: 'مفرج المجفل',
    title: 'الروائي',
    img: '/assets/images/jury/jury-8.jpeg',
    description:
      'قاص وسينارست قدم عدة مجموعات قصصية أدبية ترجم بعضها إلى لغات أخرى واستخدمت مجموعاته القصصية نماذجا للدراسات العليا في عدة جامعات سعودية كما قدم عددا من الأفلام السينمائية التي شاركت في مهرجانات اقليمية وعالمية مثل مهرجان القاهرة السينمائي ومهرجان "تورنتومن" أعماله المجموعة القصصية "وخز" والفيلم السينمائي "حد الطار"و "هجان".',
  },
  // critics
  {
    name: 'طارق الشناوي',
    title: 'ناقد',
    img: '/assets/images/jury/jury-9.jpeg',
    description:
      'طارق الشناوي ناقد سينمائي حصل على العديد من الجوائز كأفضل مقال نقدي، يعمل في النقد منذ 40 عاما ، شارك في العديد من لجان التحكيم بالمهرجانات الدولية والعربية مثل فالينسيا واوسيان وقرطاج ودبي وابوظبي، عمل على تأليف أكثر من 30 كتابا متخصصا في السينما',
  },
  {
    name: 'حسن النعمي',
    title: 'ناقد',
    img: '/assets/images/jury/jury-10.jpg',
    description:
      'قاص وناقد، وأستاذ السرديات بجامعة الملك عبدالعزيز.أنجز رسالة الدكتوراه في العلاقة بين الرواية والسينما من خلال دراسة روايات نجيب محفوظ في السينما. وله دراسات عديدة في تاريخ وتطور الرواية السعودية.',
  },
  // screenwriters
  {
    name: 'صلاح الجهيني',
    title: 'السيناريست',
    img: '/assets/images/jury/jury-11.jpeg',
    description:
      'سيناريست ومنتج مصري كتب السيناريو لعدد من الأعمال الاكثر نجاحا في السينما العربية منها ثلاثية ولاد رزق وأفلام الخلية و ١٢٢ و الفيلم الكوميدي المطاريد.',
  },
  {
    name: 'مريم نعوم',
    title: 'السيناريست',
    img: '/assets/images/jury/jury-12.png',
    description:
      'كاتبة سيناريو مصرية حائزة على عدة جوائز، تُعرف بأعمالها الاجتماعية المُلهمة التي تُعالج قضايا مهمة، خاصة تلك التي تتعلق بالنساء، تخرجت من المعهد العالي للسينما وتألقت بأفلامها القصيرة وبرامج الأطفال المُسلية والإعلانات ومسلسلات التلفزيون، تُساهم مريم في تنمية مواهب الجيل القادم من خلال "ورشة سرد للكتابة" التي أسستها عام 2016 كما تُشارك خِبرتها بنشاط من خلال ورش العمل، وتُؤمن بقوة السينما في إحداث التغيير الاجتماعي.',
  },
  {
    name: 'ياسر مدخلي ',
    title: 'السيناريست',
    img: '/assets/images/jury/jury-13.jpeg',
    description:
      'ياسر يحيى مدخلي كاتب مسرحي وسينارست، حاصل على عدة جوائز داخل وخارج المملكة، وعمل رئيسا وعضوا في لجان تحكيم سعودية وخليجية، ومنتجا ومديرا للكتابة في قطاع الاستديو بشركة صلة، شارك في مهرجانات ومؤتمرات عربية ودولية، ونالت أعماله اهتماما واسعا بأبحاث أكاديمية محكمة.',
  },
  {
    name: 'شريف نجيب',
    title: 'السيناريست',
    img: '/assets/images/jury/jury-14.jpeg',
    description:
      'كاتب سيناريو ومخرج مصري، تخرج من كلية الهندسة بجامعة القاهرة، ثم التحق بمدرسة "فانكوفر فيلم سكوول" للسينما في كندا وتخرج منها في عام 2005، ثم عاد مجددًا إلى القاهرة مكرسًا وقته منذ ذلك الوقت للكتابة السينمائية والإخراج ، قدم عدة أفلام للسينما المصرية منها "لا تراجع ولا استسلام"، و"أعز الولد" و "تسليم أهالي" و"فاصل من اللحظات اللذيذة" و"فرق خبرة" الذي كان أيضاً فيلمه الأول كمخرج.',
  },
  // producers
  {
    name: 'احمد بدوي',
    title: 'منتج',
    img: '/assets/images/jury/jury-15.jpeg',
    description:
      'أحمد بدوي هو منتج سينمائي مصري ومؤسس شركة "فيلم سكوير"، وله خبرة تمتد لأكثر من 25 عاماً في مجال الإنتاج، قام بإنتاج وشارك في إنتاج عدد كبير من الأفلام الناجحة التي حققت إقبالاً جماهيرياً واهتماماً نقدياً، بما في ذلك "ولاد رزق"، "بيت الروبي"، و"الفيل الأزرق"، والتي احتلت المراكز السبعة الأولى لأعلى الأفلام تحقيقاً للإيرادات في مصر. كما أن فيلم "بحبك"، الذي ساهم في إنتاجه، يعتبر من أعلى الأفلام المصرية تحقيقاً للإيرادات في العالم العربي. حالياً، يعمل أحمد بدوي على مشاريع متنوعة فنياً وتجاريًا، بما في ذلك فيلم "ضي"، وفيلم "الست"، والعديد من مشاريع الشركة المتحدة للإنتاج السينمائي بصفته العضو المنتدب، بالإضافة إلى إدارة صندوقBig Time" " لدعم المحتوى العربي كرئيس للجنة الفنية للصندوق.',
  },
  {
    name: 'صادق الصباح',
    title: 'منتج',
    img: '/assets/images/jury/jury-16.jpeg',
    description:
      'صادق أنور الصباح هو منتج لبناني، يمتلك شركة سيدرز آرت برودكشن (صباح أخوان)، وهي شركة رائدة في مجال الإنتاج التلفزيوني والسينمائي. بخبرة ومسيرة مهنية تمتد لثلاثة عقود، ساهم في إنتاج عدد من الأعمال الفنية في السينما والتلفزيون التي حصلت على العديد من الجوائز. وهو أيضًا نائب رئيس الاتحاد العام للمنتجين العرب.',
  },
  {
    name: 'عدنان كيال',
    title: 'منتج',
    img: '/assets/images/jury/jury-17.jpeg',
    description:
      'الرئيس التنفيذي لقطاع الفعاليات في صله والمشرف العام على قطاع الانتاج والمشرف الفني في صندوق “Big time” ومستشار مجلس إدارة الهيئة العامة للترفية، رئيس قطاع الفعاليات في شركة صلة والمشرف العام على إنتاج العروض المسرحية والأعمال السينمائية، يعد خبيرا في إنتاج الأعمال الإبداعية في شتى المجالات برز من خلال تأسيسه لعدة شركات متميزة في الإنتاج الإعلامي ونال تكريمات من جهات وشخصيات اعتبارية على المستوى المحلي والدولي ويقود عملا طموحا لإنتاجات سعودية وعربية وعالمية.',
  },
  // directors
  {
    name: 'مروان حامد',
    title: 'مخرج',
    img: '/assets/images/jury/jury-18.jpeg',
    description:
      'مروان حامد هو مخرج سينمائي مصري، وُلد في 2 مارس 1975. يعتبر من أبرز المخرجين في السينما العربية المعاصرة، وحقق شهرة واسعة من خلال أفلامه مثل "الفيل الأزرق" (2014)، و"تراب الماس" (2018)، و"سوء استخدام" (2021). تميزت أعماله بالإبداع الفني والتقني، وحصل على العديد من الجوائز والتكريمات لمساهماته في صناعة السينما.',
  },
  {
    name: 'خيري بشارة',
    title: 'مخرج',
    img: '/assets/images/jury/jury-19.jpeg',
    description:
      'مخرج مصري بارز يُعتبر من القلائل الذين أعادوا تعريف الواقعية في السينما المصرية. وتخرج الأول على دفعته في المعهد العالي للسينما في عام 1967، وأكسبه ذلك منحة دراسية لمدة عامين في بولندا لاستكمال تدريب عملي. كما قام بإخراج أفلام وثائقية نالت تقديراً كبيراً مثل "طبيب في الأرياف عام (1975). وأخرج بشارة 13 فيلمًا طويلًا حفرت اسمه بقوة في السينما المصرية، منها "الطوق والأسورة" 1986، وفلم "يوم مرّ يوم حلو" في سنة 1988،  و"آيس كريم في جليم" 1992. كما بدأ  في هذا المجال في أوائل العقد الأول من القرن الحادي والعشرين، وأخرج أيضًا ثلاث مسرحيات موسيقية، و نشر روايته الأولى "الكبرياء الصيني" في عام 2023.',
  },
  {
    name: 'عبدالإله القرشي',
    title: 'مخرج',
    img: '/assets/images/jury/jury-20.jpeg',
    description:
      'بدأ مسيرته المهنية في العمل في القطاعين الحكومي والخاص، حاصل على درجة الماجستير في صناعة الأفلام من جامعة نيويورك فيلم أكاديمي بمدينة لوس انجلوس، بدأ العمل بشكل مستقل على اخراج وانتاج فيلمه الأول "رولم"  ليكون اول فيلم سعودي يعرض في دور السينما السعودية عام 2019. والذي حصل عدة جوائز وتكريمات في مهرجانات عالمية، في عام 2023 برز كمخرج ومنتج تنفيذي لفيلمه الثاني "الهامور ح ع" والذي حقق نجاحا كبيرا ليصبح الفيلم السعودي الاول الذي يعرض تجاريا في مصر، كما تم اختياره لتمثيل المملكة العربية السعودية لجوائز  الأوسكار 96.',
  },
  {
    name: 'محمد شاكر خضير',
    title: 'مخرج',
    img: '/assets/images/jury/jury-21.jpeg',
    description:
      ' مخرج مصري حاصل على العديد من الجوائز، ارتبط اسمه بأهم الاعمال في الدراما التليفزيونية والسينما ومجال الدعاية والإعلان، اقترنت اعمال شاكر بتقدير كبير من قبل النقاد حصلت اعماله اعلى نسب المشاهدة في الوطن العربي. اخر اعماله هو مسلسل ”تحت الوصاية“ الذي حصد العديد من الجوائز المحلية والدولية.',
  },
];

export const committes = [
  {
    name: 'شيرين راشد',
    title: '',
    img: '/assets/images/jury/c1.png',
    description:
      'شيرين راشد هي مؤسسة مبادرة  “iRead" التي تهدف إلى تعزيز حب القراءة والثقافة بين الشباب في العالم العربي، تنظم شيرين مسابقات للكُتاب الجدد، كما أطلقت مبادرة "iRead Her"  و جائزة شهرزاد لتعزيز دور المرأة في الكتابة والإبداع.',
  },
  {
    name: 'رامي ابو جبرا',
    title: '',
    img: '/assets/images/jury/c2.jpeg',
    description:
      'كاتب وصحفي ومدير محتوى لديه خبرة تتجاوز 17 عاماً. عمل في الصحافة التلفزيونية منذ عام 2007، وساهم في تأسيس قنوات كبرى ناطقة باللغة العربية مثل قناة سكاي نيوز عربية وقناة "الشرق مع “Bloomberg الذي شغل منصب رئيس التحرير العام فيها، وفي عام 2021، انتقل إلى ريادة الأعمال عبر مساهمته في تأسيس تطبيق وجيز الذي بدأ بتقديم خلاصات لأهم الكتب العربية والعالمية، ثم أصبح يقدم العديد من أنواع المحتوى المميز الذي ساهم في استقطاب 5 مليون مستخدم في التطبيق.',
  },
  {
    name: 'هيلة الخلف',
    title: '',
    img: '/assets/images/jury/c3.jpeg',
    description:
      'دكتوارة في الأدب الإنجيلزي، كاتبة سيناريو ومترجمة ، تشغل حالياً منصب مدير عام الإدارة العامة للترجمة في هيئة الأدب والنشر والترجمة، كما شغلت منصب مستشارة رئيس الجامعة للأتصالات المؤسسية في جامعة الأميرة نوره بنت عبدالرحمن، و تشرف على مبادرة ترجم التي تعد أكبر مبادرة دولية مختصة بدعم ترجمة الأعمال من اللغة العربية وأليها.',
  },
  {
    name: 'أحمد رويحل',
    title: '',
    img: '/assets/images/jury/c4.png',
    description:
      'رائد أعمال مهتم بتكنولوجيا التنمية الثقافية، مُرشح لنيل درجة الدكتوراه في سُبل التطوير والاستثمار في المحتوى والنشر الرقمي، لعب دوراً أساسياً في بناء سوق النشر الرقمي، من خلال مشاركته في تأسيس عدة شركات عالمية في المنطقة العربية، ومنها "شركة كتاب صوتي" السويدية، التي تم الاستحواذ عليها من قبل  Storytel العالمية، وتأسيس شركة اربوكفيرس بالشراكة مع Bookwire العالمية، كما أنشئ استديوهات متخصصة في إنتاج الكتب الصوتية، أنتجت وادارت آلاف الكتب الصوتية العربية حول العالم. كذلك مُتحدث دائم في الفعاليات الثقافية العربية واستشاري نشر ومحتوى لعدة جهات عربية وعالمية.',
  },
  {
    name: 'رشا شوقي ',
    title: '',
    img: '/assets/images/jury/c5.jpg',
    description:
      'رشا شوقي سليمان ، بكالرويوس إدارة أعمال، أخصائية مراقبة الجودة للكتب الصوتية في مؤسسة إقرألي ،شغوفة بالأدب العربي والعالمي،  خبيرة في تقيم الكتب  والافلام وكتابة مراجعات وتحليلات ثاقبة وجذابة .',
  },
  {
    name: 'باسم الخشن',
    title: '',
    img: '/assets/images/jury/c6.jpeg',
    description:
      'باسم الخشن هو مؤسِّس ومُدير وِكالة «بيرز فاكتور»، أكبر وِكالة أدبيَّة في الشرق الأوسط ، نَجَح باسم في تمثيل مجموعة متنوِّعة من الأسماء في عالم الأدب؛ مثل براندون ساندرسون، وميتش آلبوم وآر أف كوانج و آيزك أزيموف وجابور ماتيه.',
  },
  {
    name: 'أمينة مصطفى',
    title: '',
    img: '/assets/images/jury/c7.jpeg',
    description:
      'أمينة مصطفى هي كاتبة سيناريو ومحتوى بخبرة تزيد عن ٩ سنوات، درست الإقتصاد والعلوم السياسية وحاصلة على شهادة من جامعة السوربون بباريس، ترأست فريق الكتابة في أبرز المجلات المصرية، وعملت كمديرة لكتابة المحتوى في واحدة من أهم المبادرات التي تشجع على القراءة في الشرق الأوسط وهي مبادرة iRead، وتولت إدارة فريق الكتابة في الموقع الإخباري Egypt Forward، كما إنها مدربة كتابة السيناريو وتطوير الأفلام في Arab Film School',
  },
  {
    name: 'مصطفى عبيد',
    title: '',
    img: '/assets/images/jury/c8.jpg',
    description:
      'روائي وباحث ومترجم مصري من مواليد 1976. يعمل في الصِّحافة، وصدر له 24 كتابا في التاريخ والأدب والسير، ودخلت معظم كتبه في فوائم الكتب الأكثر مبيعًا، فاز بجائزة أفضل كتاب مترجم في مَعْرِض القاهرة 2021 وحاز جوائز نِقابة الصحفيين المصريين في المقال ست مرات',
  },
  {
    name: 'ملك حسن ',
    title: '',
    img: '/assets/images/jury/c9.jpg',
    description:
      'ملك حسن هي مستشارة استراتيجية للعلامات التجارية ومصممة العلامات التجارية ، في عام 2016، أصبحت واحدة من أصغر مستشاري العلامات التجارية الذين عملوا في حاضنة حكومية، وقد عملت مع أكثر من 175 شركة ناشئة وصغيرة ومتوسطة الحجم في مصر كمستشارة ومدربة للعلامات التجارية.',
  },
];
