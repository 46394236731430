import ArrowLeft from '@/components/icons/arrow-left';
import { Image } from '@/components/shared/image';
import Link from '@/components/shared/Link/link';
import Share from '@/components/shared/share';
import Trends from '@/components/shared/trends';
import { Lang } from '@/i18n/settings';
import { getNewsImageURL } from '@/lib/utils';
import { NewsProps } from '@/services/type';

type MediaCardProps = {
  data: NewsProps;
  lang: Lang;
};

export function MediaCard({ data, lang }: MediaCardProps) {
  return (
    <div className='relative  select-none h-full bg-background flex flex-col'>
      <div className='absolute z-50 flex items-center justify-between top-4 left-4 right-4'>
        <div>
          <Trends totalReaders={data?.analytics?.readers} />
        </div>
        <div>
          <Share
            share={{
              title: data.attributes.title,
              url: `/${lang}/news/${data?.attributes.title}?id=${data?.id}`,
              text: data.attributes.content,
            }}
          />
        </div>
      </div>
      <div className=' relative w-full flex-1'>
        <Image
          src={getNewsImageURL('medium', data)}
          height={5000}
          width={5000}
          alt='media'
          className='w-full object-cover h-[300px] aspect-video'
        />
        <Link
          href={`/news/${data?.attributes.title}?id=${data?.id}`}
          className='absolute inset-0 z-10'
        ></Link>
      </div>
      <div className='bg-background p-4 max-h-[150px] h-[150px]  overflow-hidden flex flex-col gap-2'>
        <p className='text-primary line-clamp-1'>{data.attributes.title}</p>

        <div className='flex item-center justify-between'>
          <div>
            <h3 className='line-clamp-1'>{data.attributes.content}</h3>
          </div>
          <div>
            <Link
              href={`/news/${data?.attributes.title}?id=${data?.id}`}
              className='hover:scale-105 duration-200 ease-in transition-all'
            >
              <ArrowLeft />
            </Link>
          </div>
        </div>

        <div>
          <p className='line-clamp-2 text-muted-foreground '>
            {data.attributes.description?.[0]?.children?.[0]?.text}
          </p>
        </div>
      </div>
    </div>
  );
}
