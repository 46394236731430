import {
  CarouselApi,
  Carousel as CarouselComp,
  CarouselContent,
  CarouselItem,
  CarouselOptions,
} from '@/components/ui/carousel';
import AutoPlay, { AutoplayOptionsType } from 'embla-carousel-autoplay';
import React, { ReactElement } from 'react';

interface Content extends React.HTMLAttributes<HTMLDivElement> {}

type CarouselProps = {
  api?: CarouselApi;
  setApi: React.Dispatch<React.SetStateAction<CarouselApi | undefined>>;
  children: ReactElement<typeof CarouselItem> | ReactElement<typeof CarouselItem>[];
  autoPlay?: boolean;
  options?: {
    content?: Content;
    carousel?: CarouselOptions;
    autoPlay?: AutoplayOptionsType;
  };
};

export function Carousel({ setApi, options, children }: CarouselProps) {
  return (
    <CarouselComp
      setApi={setApi}
      opts={{
        align: 'start',
        direction: 'rtl',
        // loop: true,
        slidesToScroll: 1,
        skipSnaps: true,
        dragFree: false,
        inViewThreshold: 1,
        ...(options?.carousel ?? {}),
      }}
      plugins={[
        AutoPlay({
          delay: 6000,
          // active: false,
          playOnInit: false,
          stopOnInteraction: true,
        }),
      ]}
    >
      <CarouselContent className={''} {...options?.content}>
        {children}
      </CarouselContent>
    </CarouselComp>
  );
}
