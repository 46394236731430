'use client';
import { Image } from '@/components/shared/image';
import { motion } from 'framer-motion';
import { CommitteeProps } from '../data';

interface CommitteeCardProps {
  data: CommitteeProps;
  isActive?: boolean;
}

export function AnimatedCommitteeCard({ data, isActive = false, ...props }: CommitteeCardProps) {
  // Variants for the image
  const imageVariants = {
    normal: { scale: 1, y: 0 },
    scaledDown: { scale: 0.9, y: '-50%' }, // Scale down and move up
  };

  // Variants for the description
  const descriptionVariants = {
    hidden: { opacity: 0, y: '100%' },
    visible: { opacity: 1, y: '2%' }, // Description slides up to cover
  };

  // Sync timing for both animations
  const transition = { duration: 0.5, ease: 'easeInOut' };

  return (
    <motion.div className='relative overflow-hidden border-b select-none' {...props}>
      <motion.div
        className='relative bg-[#1F1F1F]  h-[450px] flex flex-col j  w-full'
        variants={imageVariants}
        animate={isActive ? 'scaledDown' : 'normal'}
        transition={transition}
      >
        <Image
          src={data.img}
          alt='media'
          priority={true}
          loading='eager'
          className='object-cover w-full flex-1'
        />
        <motion.div
          variants={descriptionVariants}
          initial='visible'
          animate={isActive ? 'hidden' : 'visible'}
          transition={transition}
          className='flex border absolute bottom-0 inset-x-0 bg-primary border-t-0 flex-col gap-2 h-[100px]  py-4 items-center justify-center'
        >
          <h4 className='font-bold text-2xl'>{data.name}</h4>
          <p className='text-background font-semibold text-xl'>{data.title}</p>
        </motion.div>
      </motion.div>
      <motion.div
        className='absolute inset-0 bg-primary border text-white p-4 flex flex-col items-center justify-center'
        variants={descriptionVariants}
        initial='hidden'
        animate={isActive ? 'visible' : 'hidden'}
        transition={transition}
      >
        <div className='flex  flex-col gap-2 py-4 items-center justify-center'>
          <h4 className='font-bold text-2xl'>{data.name}</h4>
          <p className='text-background font-semibold text-xl'>{data.title}</p>
        </div>
        <p className='text-xs md:text-sm text-center'>{data.description}</p>
      </motion.div>
    </motion.div>
  );
}
