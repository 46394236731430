'use client';
import ArrowLeft from '@/components/icons/arrow-left';
import { Carousel } from '@/components/shared/carousel';
import { Button, buttonVariants } from '@/components/ui/button';
import { CarouselApi, CarouselItem } from '@/components/ui/carousel';
import { Shell } from '@/components/ui/shell';

import { Skeleton } from '@/components/ui/skeleton';
import { useAuthCtx } from '@/contexts/auth';
import { useGetNews } from '@/services/news';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Link from 'next/link';
import React, { useEffect } from 'react';
import { MediaCard } from './components/card';
import { useLocaleCtx } from '@/contexts/locale';

export default function SectionSix() {
  const [api, setApi] = React.useState<CarouselApi>();
  const { lang } = useLocaleCtx();

  const { token } = useAuthCtx();

  const { data, refetch, isPending } = useGetNews(token as string, {
    pageSize: 10,
  });

  useEffect(() => {
    if (token && !data) {
      refetch();
    }
  }, [token, data]);

  if (!data && !isPending) {
    return null;
  }

  return (
    <div className='bg-primary relative overflow-x-hidden py-28 md:py-40'>
      <Shell as='section' className='relative gap-12 '>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-6'>
            <h1 className='text-background font-bold text-4xl'>المركز الإعلامي</h1>
            <div className='md:block hidden'>
              <Controls api={api} />
            </div>
          </div>

          <div className=' flex-col md:flex hidden items-center justify-center'>
            <Link
              href='/news'
              className={buttonVariants({
                className: 'gap-4 !bg-[#E6E6E6] hover:!bg-[#E6E6E6]/80 duration-200',
              })}
            >
              <span>عرض جميع الاخبار</span>
              <ArrowLeft color='black' />
            </Link>
          </div>
        </div>

        <Carousel
          setApi={setApi}
          options={{
            carousel: {
              align: 'start',
              loop: true,
            },
          }}
        >
          {isPending ? (
            <>
              {Array.from({ length: 5 }).map((_, i) => (
                <CarouselItem key={i} className='md:basis-1/2 lg:basis-1/2'>
                  <Loading />
                </CarouselItem>
              ))}
            </>
          ) : (
            <>
              {(data ?? []).map((news) => (
                <CarouselItem key={news.id} className='md:basis-1/2 lg:basis-1/2 h-full'>
                  <MediaCard data={news} lang={lang} />
                </CarouselItem>
              ))}
            </>
          )}
        </Carousel>

        <div className='md:hidden block'>
          <Controls api={api} />
        </div>
      </Shell>
    </div>
  );
}

function Loading() {
  return (
    <div className='flex flex-col'>
      <Skeleton className='h-[300px] mb-0 w-full' />
      <div className='bg-background p-4 grid gap-2'>
        <Skeleton className='h-4 w-[100px]' />
        <Skeleton className='h-4 w-[200px]' />
        <Skeleton className='h-4 w-full' />
        <Skeleton className='h-4 w-[80%]' />
      </div>
    </div>
  );
}

function Controls({ api }: { api?: CarouselApi }) {
  return (
    <div className='flex items-center gap-4'>
      <Button
        onClick={() => api?.canScrollPrev() && api?.scrollPrev()}
        variant={'outline'}
        size={'icon'}
        className='bg-transparent rounded-full text-background'
      >
        <ChevronRight />
      </Button>

      <Button
        onClick={() => api?.canScrollNext() && api?.scrollNext()}
        variant={'outline'}
        size={'icon'}
        className='bg-transparent rounded-full text-background'
      >
        <ChevronLeft />
      </Button>
    </div>
  );
}
