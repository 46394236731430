'use client';
import Reveal from '@/components/shared/animate/reveal';
import { Carousel } from '@/components/shared/carousel';
import { Title } from '@/components/shared/title';
import { CarouselApi, CarouselItem } from '@/components/ui/carousel';
import { Shell } from '@/components/ui/shell';
import { useInView } from 'framer-motion';
import React from 'react';
import { Controls } from './components/carousel-controls';
import { CarouselIndicators } from './components/carousel-indicators';
import { prizes } from './prizes';

export function SectionFive() {
  const ref = React.useRef(null);
  const [api, setApi] = React.useState<CarouselApi>();

  const isInView = useInView(ref, {
    amount: 0.5,
  });

  function handleResetOnInteraction() {
    const autoPlay = api?.plugins()?.autoplay;
    if (!autoPlay) return;

    autoPlay?.isPlaying() && autoPlay?.reset();
  }

  React.useEffect(() => {
    if (isInView) {
      api?.plugins()?.autoplay?.play();
    } else {
      api?.plugins()?.autoplay?.stop();
    }
  }, [isInView]);

  return (
    <div id={'awards'} className=' w-full min-h-dvh py-6 my-auto flex items-center justify-center'>
      <Shell as={'section'} className='items-center gap-12 lg:gap-20 justify-center'>
        <div className='flex items-center justify-center flex-col gap-4 '>
          {/* <Title className='text-primary lg:text-6xl '>الجوائز المقدمة</Title> */}
          <Title className='text-primary text-2xl md:text-4xl whitespace-nowrap  lg:text-6xl '>
            مجموع جوائز المسابقة 740,000$
          </Title>
        </div>
        <Reveal delay={0.4} className='md:mt-10 flex flex-col gap-8 relative w-full'>
          <div ref={ref} className='relative w-full'>
            <div className='absolute top-[50%]   inset-x-0 md:px-8 px-4 z-50 pointer-events-auto w-full'>
              <Controls
                handleResetOnInteraction={handleResetOnInteraction}
                api={api}
                className='flex items-center justify-between w-full'
              />
            </div>

            <Carousel
              setApi={setApi}
              options={{
                carousel: {
                  align: 'center',
                },
              }}
              // autoPlay={true}
            >
              {prizes.map((prize, index) => (
                <CarouselItem
                  onMouseEnter={() => api?.plugins()?.autoplay?.stop()}
                  onMouseLeave={() => api?.plugins()?.autoplay?.play()}
                  onClick={() => {
                    if (api?.plugins()?.autoplay?.isPlaying()) {
                      api?.plugins()?.autoplay?.stop();
                      return;
                    }

                    api?.plugins()?.autoplay?.play();
                  }}
                  key={index}
                  className='flex select-none items-center justify-center'
                >
                  <div className='flex flex-col items-center text-lg justify-center gap-8 max-w-[330px] md:max-w-[600px] lg:max-w-[800px] xl:max-w-full '>
                    <Title className='text-primary text-2xl md:text-4xl  lg:text-6xl text-center'>
                      {prize.title}
                    </Title>
                    <p className='text-primary  text-xl text-center lg:max-w-fit'>
                      {prize.subtitle}
                    </p>

                    <div className='text-center'>{prize.details}</div>
                  </div>
                </CarouselItem>
              ))}
            </Carousel>
          </div>
          <div className='mt-10'>
            <CarouselIndicators api={api} handleResetOnInteraction={handleResetOnInteraction} />
          </div>
        </Reveal>
      </Shell>
    </div>
  );
}
