'use client';
import { Button } from '@/components/ui/button';
import { CarouselApi } from '@/components/ui/carousel';
import { cn } from '@/lib/utils';
import { ChevronLeft, ChevronRight } from 'lucide-react';

export function Controls({
  api,
  className,
  handleResetOnInteraction,
}: {
  api?: CarouselApi;
  className?: string;
  handleResetOnInteraction?: () => void;
}) {
  return (
    <div className={cn(className)}>
      <div className=''>
        <Button
          onClick={() => {
            handleResetOnInteraction?.();
            api?.canScrollPrev() && api?.scrollPrev();
          }}
          variant={'outline'}
          size={'icon'}
          className='bg-transparent rounded-full text-primary border-primary'
        >
          <ChevronRight />
        </Button>
      </div>
      <div className=''>
        <Button
          onClick={() => {
            handleResetOnInteraction?.();
            api?.canScrollNext() && api?.scrollNext();
          }}
          variant={'outline'}
          size={'icon'}
          className='bg-transparent rounded-full text-primary border-primary'
        >
          <ChevronLeft />
        </Button>
      </div>
    </div>
  );
}
