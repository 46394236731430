import { cn } from '@/lib/utils';
import { Image } from './shared/image';

export default function BackgroundVideo({ className }: { className?: string }) {
  return (
    <div className={cn('video-background', className)}>
      <video
        className='hidden md:block'
        id='hero-video'
        controls={false}
        disablePictureInPicture
        autoPlay
        muted
        loop
        playsInline
        style={{ width: '100%', height: 'auto' }} // Ensure the video takes full width
      >
        <source src='/assets/videos/bg-video-alt.mp4' type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      <Image
        id='hero-video-fallback'
        alt='Fallback Image'
        src='/assets/images/bg-video.png'
        className='w-full md:hidden h-full object-cover'
        width={1000}
        height={1000}
      />
    </div>
  );
}
