import { SVGProps } from 'react';

export default function ArrowLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={34} height={16} fill='none' {...props}>
      <path
        fill={props.color ?? '#E6E6E6'}
        d='M33 9a1 1 0 1 0 0-2v2ZM.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293ZM33 7H1v2h32V7Z'
      />
    </svg>
  );
}
