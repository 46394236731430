import Flame from '@/components/icons/flame';
import { Button, ButtonProps } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface Props extends ButtonProps {
  totalReaders: number;
}

export default function Trends({ totalReaders = 0, className, ...props }: Props) {
  return (
    <Button
      variant={'ghost'}
      className={cn(
        'items-center gap-2 cursor-default duration-200 ease-in transition-all bg-black/40 h-11 hover:bg-black/30',
        className
      )}
      {...props}
    >
      <Flame className='text-primary' />
      <span className='text-xs'>{totalReaders}</span>
      <span className='text-xs'>مشاهد</span>
    </Button>
  );
}
