import { cn } from '@/lib/utils';

interface TitleProps extends React.HTMLProps<HTMLHeadingElement> {}

export function Title({ children, className, ...rest }: TitleProps) {
  return (
    <h1 className={cn('text-4xl font-bold', className)} {...rest}>
      {children}
    </h1>
  );
}
