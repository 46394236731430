import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

/**
 * Defines variants for the `Shell` component's styling using `cva`.
 *
 * The `shellVariants` function configures different styles based on the `variant` prop.
 *
 * @example
 * ```ts
 * shellVariants({ variant: 'card' }) // Returns 'w-full rounded-lg'
 * ```
 */
const shellVariants = cva(
  'lg:grid flex flex-col lg:items-center overflow-x-hidden px-4 gap-8 pb-8 pt-6 md:py-8',
  {
    variants: {
      variant: {
        default: 'container',
        sidebar: '',
        card: 'w-full rounded-lg',
        centered: 'container flex h-[100dvh] max-w-2xl flex-col justify-center',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

/**
 * Props for the `Shell` component.
 *
 * Extends standard `HTMLDivElement` attributes and `VariantProps` from `shellVariants`.
 *
 * @extends React.HTMLAttributes<HTMLDivElement>
 * @extends VariantProps<typeof shellVariants>
 */
interface ShellProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof shellVariants> {
  /**
   * The component type to render.
   * Defaults to `'section'`.
   */
  as?: React.ElementType;
}

/**
 * A versatile container component that adapts its styles based on the `variant` prop.
 *
 * Renders a component (`as` prop) with conditional styles applied via `shellVariants`.
 *
 * @param {ShellProps} props - The properties to apply to the component.
 * @param {string} [props.className] - Optional additional CSS class names.
 * @param {React.ElementType} [props.as='section'] - The HTML element or custom component to render.
 * @param {keyof VariantProps<typeof shellVariants>['variant']} [props.variant] - The variant to apply for conditional styling.
 *
 * @example
 * ```tsx
 * <Shell variant="card" className="my-custom-class">
 *   Content goes here
 * </Shell>
 * ```
 *
 * @returns {JSX.Element} The rendered component with applied styles and properties.
 */
function Shell({ className, as: Comp = 'section', variant, ...props }: ShellProps) {
  return <Comp className={cn(shellVariants({ variant }), className)} {...props} />;
}

export { Shell, shellVariants };
