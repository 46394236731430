import React from 'react';
import { Image, ImageProps } from './image';
import LOGO from '~/public/assets/sponsors/logo-gold.png';
import { StaticImageData } from 'next/image';
import { cn } from '@/lib/utils';

interface Props extends Omit<ImageProps, 'src' | 'alt'> {
  src?: StaticImageData;
  containerClassName?: string;
}

export default function Logo({ src, containerClassName, ...rest }: Props) {
  return (
    <div className={cn('max-w-[40px] md:max-w-[50px]', containerClassName)}>
      <Image src={src ?? LOGO} width={500} height={500} alt='logo' {...rest} />
    </div>
  );
}
