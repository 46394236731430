import * as React from 'react';
import { SVGProps } from 'react';

const Flame = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <path
      fill='#BD8B2C'
      fillRule='evenodd'
      d='M7.462 2.174a1.286 1.286 0 0 1 1.608.848l.698 2.26A12.202 12.202 0 0 1 12 5.079c.789 0 1.534.072 2.232.203l.698-2.26a1.286 1.286 0 1 1 2.458.76l-.696 2.249a12.155 12.155 0 0 1 3.336 2.16l1.689-1.689a1.286 1.286 0 0 1 1.819 1.817l-1.77 1.771c.252.335.467.648.643.926.213.338.374.627.485.847.07.138.135.28.192.423a1.31 1.31 0 0 1 0 .945 5.355 5.355 0 0 1-.192.423c-.148.29-.31.571-.485.845a13.822 13.822 0 0 1-1.949 2.417c-1.774 1.753-4.553 3.522-8.46 3.522-3.907 0-6.686-1.768-8.458-3.518a13.827 13.827 0 0 1-1.95-2.42 10.674 10.674 0 0 1-.486-.846 5.452 5.452 0 0 1-.192-.423 1.573 1.573 0 0 1-.06-.213c-.051-.245-.03-.5.06-.734.058-.143.122-.285.192-.423.325-.62.703-1.213 1.13-1.77L.462 8.32A1.286 1.286 0 0 1 2.28 6.501l1.69 1.688a12.206 12.206 0 0 1 3.336-2.16L6.61 3.78a1.286 1.286 0 0 1 .849-1.607l.003.002Zm3.727 14.122a3.63 3.63 0 1 0 1.62-7.076 3.63 3.63 0 0 0-1.62 7.076Z'
      clipRule='evenodd'
    />
  </svg>
);
export default Flame;
