'use client';
import { Button, ButtonProps } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Share2 } from 'lucide-react';
import { RWebShare } from 'react-web-share';

interface Props extends ButtonProps {
  share: {
    text: string;
    url: string;
    title: string;
  };
}

export default function Share({ className, share, ...props }: Props) {
  return (
    <RWebShare
      data={{
        text: share?.text,
        url: share?.url,
        title: share?.title,
      }}
      // onClick={() => console.info('share successful!')}
    >
      <Button
        variant={'ghost'}
        className={cn(
          'items-center gap-2 duration-200 ease-in transition-all bg-black/40 h-11 hover:bg-black/30',
          className
        )}
        {...props}
      >
        <Share2 className='text-primary' fill='#BD8B2C' />
      </Button>
    </RWebShare>
  );
}
