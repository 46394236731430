'use client';

import i18next, { FlatNamespace, KeyPrefix } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import {
  FallbackNs,
  initReactI18next,
  UseTranslationOptions,
  useTranslation as useTranslationOrg,
  UseTranslationResponse,
} from 'react-i18next';
// import LocizeBackend from 'i18next-locize-backend'
import LanguageDetector from 'i18next-browser-languagedetector';
import { cookieName, getOptions, Lang, languages } from './settings';

const runsOnServerSide = typeof window === 'undefined';

// on client side the normal singleton is ok
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) => import(`./locales/${language}/${namespace}.json`)
    )
  )
  // .use(LocizeBackend) // locize backend could be used on client side, but prefer to keep it in sync with server side
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
      order: ['path', 'htmlTag', 'cookie', 'navigator'],
    },
    preload: runsOnServerSide ? languages : [],
  });

/**
 * A custom React hook for managing and using translation within a React client component.
 *
 * @template Ns - The namespace type used for translations.
 * @template KPrefix - The prefix type for keys in the translations.
 *
 * @param lng - The language code to be used. This will change the language if different from the currently resolved language.
 * @param ns - The namespace(s) for the translations. If not provided, the default namespace will be used.
 * @param options - Optional configuration for the translation hook.
 *
 * @returns An object containing the translation functions and the i18n instance.
 *
 * @remarks
 * - The hook will set the language in cookies for persistence.
 * - If `runsOnServerSide` is true, the language is immediately changed on the server side.
 * - On the client side, the hook will synchronize the active language with the resolved language and update cookies accordingly.
 *
 * @example
 * ```ts
 * const { t } = useTranslation('en', 'common');
 * // `t` is a function that can be used for translating keys
 * ```
 *
 * @template Ns - The namespace type for the translations.
 * @template KPrefix - The key prefix type for translations.
 */
export function useTranslation<
  Ns extends FlatNamespace,
  KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined,
>(
  lng: Lang,
  ns?: Ns,
  options?: UseTranslationOptions<KPrefix>
): UseTranslationResponse<FallbackNs<Ns>, KPrefix> {
  const [cookies, setCookie] = useCookies([cookieName]);
  const ret = useTranslationOrg(ns, options);
  const { i18n } = ret;
  if (runsOnServerSide && lng && i18n.resolvedLanguage !== lng) {
    i18n.changeLanguage(lng);
  } else {
    const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage);

    useEffect(() => {
      if (activeLng === i18n.resolvedLanguage) return;
      setActiveLng(i18n.resolvedLanguage);
    }, [activeLng, i18n.resolvedLanguage]);

    useEffect(() => {
      if (!lng || i18n.resolvedLanguage === lng) return;
      i18n.changeLanguage(lng);
    }, [lng, i18n]);

    useEffect(() => {
      if (cookies.i18next === lng) return;
      setCookie(cookieName, lng, { path: '/' });
    }, [lng, cookies.i18next]);
  }
  return ret;
}
